
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "partners",
  components: {
    CardTitleJaya,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Partenaires");
    });

    return { openLinkAgriBest: openLinkAgriBest };
  },
});
